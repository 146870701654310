<template>
  <div class="mb-5">
    <b-col md="11" offset-md="1" id="#home">
      <!-- Streamline banner section -->
      <div class="container-fluid my-3 streamline">
        <b-row class="justify-content-center">
          <b-col cols="12" md="10" lg="6" class="p-lg-0 px-md-4 px-2">
            <h1 class="streamline__title mt-md-5 mt-0 mb-0">
              Streamline your Business with Ahidi<span class="textSecondry"
                >.</span
              >
            </h1>
            <img
              src="../assets/Group 409.png"
              width="50%"
              class="streamline__title__img mt-lg-n4 mt-0"
            />
            <img
              src="../assets/StreamlineBanner.png"
              width="100%"
              class="d-md-none d-flex mx-auto"
            />
            <p class="streamline__title__description">
              The most powerful platform to run your business with ease and
              transparency. Access all features from wherever you are on your
              computer or mobile.
            </p>
            <div class="my-sm-5 my-3 d-flex align-self-center">
              <b-button
                variant="warning"
                class="streamline__btn--getStarted text-white"
                :href="vendorUrl"
              >
                Get Started
              </b-button>
              <a
                :href="vendorUrl"
                class="streamline__demo px-sm-5 px-xs-4 px-3 align-self-center"
              >
                Get a free demo→
              </a>
            </div>
          </b-col>
          <b-col cols="12" md="10" lg="6" class="p-0 d-md-block d-none">
            <img
              src="../assets/StreamlineBanner.png"
              width="100%"
              class="d-flex mx-auto"
            />
          </b-col>
        </b-row></div
    ></b-col>
    <!-- paymet cards section-->
    <div class="container my-5">
      <b-row class="text-center">
        <b-col lg="3" sm="6" class="align-self-center pr-0 pr-lg-5">
          <img src="../assets/M-PESA_LOGO-01 1.png" class="w-auto" />
        </b-col>
        <b-col lg="3" sm="6" class="align-self-center">
          <img src="../assets/PayPal 1.png" class="w-auto" />
        </b-col>
        <b-col lg="3" sm="6" class="align-self-center">
          <img src="../assets/Mastercard.png" class="w-auto" />
        </b-col>
        <b-col lg="3" sm="6" class="align-self-center">
          <img src="../assets/Visa_Logo 1.png" class="w-auto" />
        </b-col>
      </b-row>
    </div>
    <!-- </b-col> -->
  </div>
</template>

<script>
export default {
  name: "Streamline",
  computed: {
    vendorUrl() {
      return this.$store.getters.country.country_code === "KE" ? "https://vendor.ahidi.co.ke" : "https://vendor.ahidi.com.au"
    }
  },
};
</script>

<style lang="scss" scoped>
.textSecondry {
  color: #ff8200;
}

.streamline {
  &__title {
    font-family: "Poppins", sans-serif;
    font-size: 78px;
    font-weight: 600;
    line-height: 85px;
    @media (max-width: 599.98px) {
      font-size: 40px;
      line-height: 40px;
    }
  }
  &__title__img {
    // margin-top: -50px;
  }
  &__title__description {
    font-size: 22px;
    font-weight: 400;
    font-family: "Ubuntu", sans-serif;
    margin-right: 60px;
    color: rgba(43, 43, 43, 0.81);
    @media (max-width: 599.98px) {
      margin-right: 0;
      font-size: 18px;
      line-height: 1.2;
    }
  }
  &__btn--getStarted {
    width: 197px;
    height: 50px;
    font-size: 18px;
    @media (max-width: 599.98px) {
      width: 150px;
      font-size: 16px;
    }
  }
  &__demo {
    color: #2b2b2be5;
    font-size: 18px;
    font-weight: 500;
    &:hover {
      text-decoration: none;
    }
    @media (max-width: 599.98px) {
      font-size: 16px;
    }
  }
}
</style>
