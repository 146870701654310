<template>
  <div>
    <b-container>
      <div class="my-5 convenient">
        <!-- <b-col md="9" offset-md=""> -->
        <h1 class="convenient__title text-center">
          A New convenient, flexible & dependable platform for ahidi customers.
        </h1>
        <!-- </b-col> -->
        <div class="container-fluid my-5">
          <b-row class="convenient__alignSm">
            <b-col cols="12" xl="3" lg="4" md="6" class="py-xl-0 py-3">
              <b-card class="convenient__cardBody">
                <div class="convenient__rectangle convenient__rectangle--card1">
                  <img src="../assets/Shop.png" width="50%" />
                </div>
                <p class="convenient__card--title mt-3">Book Appointments</p>
                <p class="convenient__card--description">
                  You don’t have to wait in line anymore. Book appointments in
                  advance.
                </p>
              </b-card>
            </b-col>
            <b-col cols="12" xl="3" lg="4" md="6" class="py-xl-0 py-3">
              <b-card class="convenient__cardBody--active">
                <div class="convenient__rectangle convenient__rectangle--card2">
                  <img src="../assets/Person.png" width="50%" />
                </div>
                <p class="convenient__card--title mt-3 text-white">
                  Payment Options
                </p>
                <p class="convenient__card--description text-white">
                  Choose from a wide variety of payment options that best suits
                  you.
                </p>
              </b-card>
            </b-col>

            <b-col cols="12" xl="3" lg="4" md="6" class="py-xl-0 py-3">
              <b-card class="convenient__cardBody">
                <div class="convenient__rectangle convenient__rectangle--card3">
                  <img src="../assets/Estimate.png" width="50%" />
                </div>
                <p class="convenient__card--title mt-3">
                  Purchase Gifts Vouchers & Memberships
                </p>
                <p class="convenient__card--description">
                  Save and ejoy gifts and vouchers on the stores on Ahidi.
                </p>
              </b-card>
            </b-col>
            <b-col cols="12" xl="3" lg="4" md="6" class="py-xl-0 py-3">
              <b-card class="convenient__cardBody">
                <div class="convenient__rectangle convenient__rectangle--card4">
                  <img src="../assets/Web Advertising.png" width="50%" />
                </div>

                <p class="convenient__card--title mt-3">
                  Buy Products At Your Convenience
                </p>
                <p class="convenient__card--description">
                  Easily Purchase Products Online.
                </p>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "NewConvenient",
};
</script>

<style lang="scss" scoped>
.convenient {
  &__title {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 70px;
    text-transform: capitalize;
  }
  &__alignSm {
    justify-content: center;
    @media (max-width: 767.98px) {
      text-align: -webkit-center;
    }
  }
  &__cardBody {
    // width: 258px;
    // height: 273.38px;
    border-radius: 8px;
    padding-bottom: 20px;
    border: 0;
  }
  &__rectangle {
    width: 56px;
    height: 56px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__card--title {
    font-style: normal;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #2b2b2b;
  }
  &__card--description {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #646464;
  }
  &__cardBody--active {
    // width: 258px;
    // height: 300.38px;
    background: rgba(255, 130, 0, 0.87);
    border-radius: 8px;
    padding-bottom: 20px;
  }
  &__rectangle--card1 {
    background: #ff8200 !important;
  }
  &__rectangle--card2 {
    background: white !important;
  }
  &__rectangle--card3 {
    background: #ff7277 !important;
  }
  &__rectangle--card4 {
    background: #f8c42c !important;
  }
}
</style>
