<template>
  <div class="footer">
    <b-container fluid="lg">
      <b-row class="mt-7">
        <b-col cols="12" md="6" lg="4" class="logo-section pt-1 mt-sm-0 mt-3">
          <img src="../../assets/apple-touch-icon-76x76.png" class="ml-md-n7 ml-0" />

          <div>
            <p class="text-normal pr-5 my-3">
              Acquiring services made easier for consumers and vendors. Get
              started today!
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="6" lg="3" class="mt-sm-0 mt-3">
          <strong class="text_heading text-white">Our Services</strong>

          <ul class="mt-3 px-0">
            <li>
              <a href="#" class="">Sevices software</a>
            </li>
            <li>
              <a href="#" class="">Booking & Scheduling</a>
            </li>
            <li>
              <a href="#" class="">Websites for businesses</a>
            </li>
          </ul>
        </b-col>
        <b-col cols="12" md="12" lg="5">
          <b-row>
            <b-col cols="12" md="6" lg="6" class="mt-sm-0 mt-3">
              <strong class="text_heading text-white">Our Product</strong>

              <ul class="mt-3 px-0">
                <li>
                  <a href="#" class="">Free Mobile App</a>
                </li>
                <li>
                  <a href="#" class="">Services Software</a>
                </li>
                <li>
                  <a href="#" class="">Payment Solutions</a>
                </li>
              </ul>
            </b-col>
            <b-col cols="12" md="6" lg="6" class="text-white mt-sm-0 mt-3">
              <strong class="text_heading">Contact</strong>

              <ul class="mt-3 px-0 text-white">
                <li v-if="$store.getters.country.country_code === 'AU'">
                  <a href="tel:0416209465" class="">0416209465</a>
                </li>
                <!-- <li v-if="country_code === 'KE'">
                  <a href="tel:+254754656" class="">+254754656</a>
                </li> -->
                <li>
                  <a href="mailto:info@agizo.net" class="">info@agizo.net</a>
                </li>
              </ul>
            </b-col>
          </b-row></b-col
        ></b-row
      >
      <b-row no-gutters>
        <b-col cols="12" class="text-left px-7 mt-sm-0 mt-3">
          <p class="text-white text-normal">
            <a :href="termsUrl" target="_blank">Terms & Conditions/Privacy</a>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import logo from "../../assets/android-chrome-192x192.png";

export default {
  components: {
    logo,
  },
  computed: {
    termsUrl() {
      return this.$store.getters.country.country_code === "KE" ? "/Terms_of_Use_KE.pdf" : "/Terms_of_Use_AU.pdf";
    }
  },
};
</script>

<style  scoped>
.footer {
  background-color: #110a38;
  width: 100%;
  height: 300px;
  padding: 50px 20px;
}
@media screen and (max-width: 990px) {
  .footer {
    /* background: url("~/static/images/Group 679.svg");
    background-blend-mode: initial;
    background-size: cover; */
    height: auto;
  }
}

ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 6px;
  margin-left: 0;
  padding-left: 0;
}

li a,
.text-normal {
  text-decoration: none;
  padding-left: 0;
  margin-left: 0;
  color: rgba(255, 255, 255, 0.72);
  font-size: 18px;
}
li a:hover {
  color: #fff;
}
.text_heading {
  font-size: 25px;
  font-weight: 500 !important;
}
</style>