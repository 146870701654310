<template>
  <div class="container testimonial my-5">
    <div class="text-center pt-5">
      <p class="testimonial__title">Testimonial</p>
      <h4 class="testimonial__title__detail">What Our Clients Say</h4>
    </div>
    <!-- <b-col md="11" offset-md="1"> -->
    <b-row class="mt-5 justify-content-center">
      <b-col cols="12" md="8" lg="6">
        <div class="testimonial__body mb-4 mb-lg-0 px-4 pt-4 pb-5">
          <b-row class="mt-4">
            <b-col sm="4">
              <img class="testimonial_image" src="/ora_nail_spot.jpg" />
            </b-col>
            <b-col sm="8" class="align-self-center">
              <h6 class="testimonial__name">Ora Nail Spot</h6>
              <img src="../assets/rating.png" />
            </b-col>
          </b-row>
          <p class="testimonial__text mt-5 mb-4">
            My nail salon has gotten so much easier to manage since i started using Ahidi
          </p>
        </div>
      </b-col>
      <b-col cols="12" md="8" lg="6">
        <div class="testimonial__body mb-4 mb-lg-0 px-4 pt-4 pb-5">
          <b-row class="mt-4">
            <b-col sm="4">
              <img class="testimonial_image" src="/teshi.jpg" />
            </b-col>
            <b-col sm="8" class="align-self-center">
              <h6 class="testimonial__name">Teshi's Beauty Place</h6>
              <img src="../assets/rating.png" />
            </b-col>
          </b-row>
          <p class="testimonial__text mt-5 mb-4">
            We can now take in online bookings thanks to Ahidi.
          </p>
        </div>
      </b-col>
    </b-row>
    <!-- </b-col> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.testimonial {
  &__title {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.3px;
    color: #ff8200;
  }
  &__title__detail {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 52px;
    letter-spacing: 0.3px;
    color: #2b2b2b;
  }
  &__body {
    // width: 522px;
    // height: 353px;
    background: #ffffff;
    box-shadow: 30px 40px 100px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    @media (max-width: 580px) {
      // height: 412px;
    }
  }
  &__name {
    // font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 145%;
    color: #2b2b2b;
  }
  &__text {
    // font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 180%;
    color: #2b2b2b;
  }

  .testimonial_image {
    height: 150px;
  }
}
</style>
