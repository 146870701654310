<template>
  <div id="app">
    <Navbar />

    <router-view />
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/pricing">Pricing</router-link>
    </div> -->
  </div>
</template>
<script>
import Navbar from "./components/Navbar.vue";

export default {
  components: {
    Navbar,
  },
  created() {
    // this.$store.dispatch("getCountry")
  }
};
</script>
<style lang="scss">
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
      text-decoration: underline;
    }
  }
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff !important;
  border-color: #ff8200 !important;
  background-color: #ff8200 !important;
}
</style>
