<template>
  <div class="text-center my-5 py-5 signUp">
    <p class="signUp__title mt-5">Sign Up Today</p>
    <h1 class="signUp__text">
      Do you have an Ahidi <br />
      account?
    </h1>
    <b-button
      variant="warning"
      class="text-white btn-lg mt-4 px-5 py-2"
      :href="vendorUrl"
    >
      Get Started
    </b-button>
    <!-- scroll icon -->
    <div class="text-right px-5">
      <a href="#">
        <img src="../assets/scroll arow.png" class="arrow" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    vendorUrl() {
      return this.$store.getters.country.country_code === "KE" ? "https://vendor.ahidi.co.ke" : "https://vendor.ahidi.com.au";
    }
  },
};
</script>

<style lang="scss" scoped>
.signUp {
  &__title {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.3px;
    color: #ff8200;
  }
  &__text {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 52px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #000000;
  }
  .arrow {
    margin-top: -100px;
    cursor: pointer;
    @media (max-width: 580px) {
      width: 15%;
    }
  }
}
</style>
