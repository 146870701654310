<template>
  <div class="home">
    <!-- <Navbar /> -->
    <Streamline id="for-vendors" />
    <Schedule />
    <BuildYourOwn />
    <NewConvenient id="for-consumers" />
    <AvoidQueues />
    <Testimonial />
    <SignUpToday />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Streamline from "../components/Streamline.vue";
import Schedule from "../components/Schedule.vue";
import BuildYourOwn from "../components/BuildYourOwn.vue";
import NewConvenient from "../components/NewConvenient.vue";
import AvoidQueues from "../components/AvoidQueues.vue";
import Testimonial from "../components/Testimonial.vue";
import SignUpToday from "../components/SignUpToday.vue";
import Footer from "../components/global/Footer.vue";

export default {
  name: "Home",
  components: {
    Navbar,
    Streamline,
    Schedule,
    BuildYourOwn,
    NewConvenient,
    AvoidQueues,
    Testimonial,
    SignUpToday,
    Footer,
  },
};
</script>
