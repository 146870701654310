import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const API_URL = process.env.VUE_APP_API_URL;
const COUNTRY_CODE = process.env.VUE_APP_COUNTRY_CODE;

export default new Vuex.Store({
  state: {
    country: { country_code: COUNTRY_CODE }
  },
  getters: {
    country: (state) => state.country, 
  },
  mutations: {
    SET_COUNTRY: (state, country) => {
      state.country = country;
    }
  },
  actions: {
    getCountry(context) {
      fetch(API_URL, { headers: { "app-id": "ke.co.agizo.vendor" } })
        .then((res) => res.json())
        .then((res) => {
          if (res && res.data) {
            context.commit("SET_COUNTRY", res.data);
          };
        })
    }
  },
  modules: {
  }
})
