<template>
  <div class="pr-0 avoid mt-5 pt-5">
    <!-- <b-col md="11"  class="pr-0 avoid"> -->
    <div class="container">
      <b-row class="justify-content-center">
        <b-col xl="6" lg="8" cols="12" class="mb-5"
          ><img src="../assets/Frame 7.png" width="85%" class="mx-auto d-flex"
        /></b-col>
        <b-col xl="6" lg="8" cols="12" class="pr-5 my-5">
          <div>
            <h1 class="avoid__title">
              Avoid queues by booking an appointment anytime.
            </h1>
            <p class="avoid__desc mt-4">
              Book an appointment to avoid waiting in queues for too long. Get
              notifications when your booking is already set.
            </p>
            <b-button
              variant="warning"
              class="text-white btn-lg mt-4 px-5 py-3"
              :href="vendorUrl"
            >
              Get Started
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- Browse A Wide Variety -->
    <div class="container mt-5">
      <b-row class="justify-content-center">
        <b-col xl="6" lg="8" cols="12" class="my-5 browse"
          ><div>
            <h1 class="browse__title">
              browse a wide variety of services and products on ahidi app
            </h1>
            <p class="browse__desc">
              Explore services and products on the Ahidi mobile app. Schedule
              appointments and communicate in real time.
            </p>
            <div class="d-sm-flex d-block mx-sm-0 mx-auto">
              <a :href="googlePlayUrl">
                <img src="../assets/google-play.png" class="mr-4 mb-sm-0 mb-3" />
              </a>
              
              <a :href="appStoreUrl">
                <img src="../assets/iphone-app-store.png" />
              </a>
            </div></div
        ></b-col>
        <b-col xl="6" lg="8" cols="12" class="">
          <img
            src="../assets/iphones.png"
            width="100%"
            class="mx-auto d-flex"
          />
        </b-col>
      </b-row>
    </div>
    <!-- </b-col> -->
  </div>
</template>

<script>
export default {
  computed: {
    appStoreUrl() {
      return this.$store.getters.country.country_code === "KE" ? "https://apps.apple.com/au/app/ahidi-kenya/id1572230894" : "https://apps.apple.com/au/app/ahidi-australia/id1573021254";
    },
    googlePlayUrl() {
      return this.$store.getters.country.country_code === "KE" ? "https://play.google.com/store/apps/details?id=ke.co.ahidi.admin" : "https://play.google.com/store/apps/details?id=ke.co.ahidi.admin";
    },
    vendorUrl() {
      return this.$store.getters.country.country_code === "KE" ? "https://vendor.ahidi.co.ke" : "https://vendor.ahidi.com.au";
    }
  },
};
</script>

<style lang="scss" scoped>
.avoid {
  &__title {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 41px;
    line-height: 70px;
    text-transform: capitalize;
    color: #2b2b2b;
  }
  &__desc {
    font-weight: 500;
    font-size: 23px;
    line-height: 45px;
    color: #808095;
    padding-right: 20px;
  }
  .browse {
    &__title {
      font-family: "Ubuntu";
      font-size: 46px;
      line-height: 70px;
      color: #2b2b2b;
      text-transform: capitalize;
    }
    &__desc {
      font-weight: 500;
      font-size: 25px;
      line-height: 45px;
      color: #808095;
      text-transform: capitalize;
      padding-right: 20px;
    }
  }
}
</style>
