<template>
  <div>
    <b-container fluid="md">
      <div class="my-5 schedule">
        <h1 class="schedule__title text-center mx-md-5 mx-0 px-lg-5 px-0">
          Schedule better, Plan better and Expand Your Reach to new customers
        </h1>
        <div class="container-fluid my-5">
          <b-row class="schedule__alignSm">
            <b-col cols="12" xl="3" lg="4" md="6" class="py-xl-0 py-3">
              <b-card class="schedule__cardBody">
                <div class="schedule__rectangle schedule__rectangle--card1">
                  <img src="../assets/Shop.png" width="50%" />
                </div>
                <p class="schedule__card--title mt-3">Build Online Shops</p>
                <p class="schedule__card--description">
                  Set up your own shops fast and easy to offer products &
                  services.
                </p>
              </b-card>
            </b-col>
            <b-col cols="12" xl="3" lg="4" md="6" class="py-xl-0 py-3">
              <b-card class="schedule__cardBody--active">
                <div class="schedule__rectangle schedule__rectangle--card2">
                  <img src="../assets/Person.png" width="50%" />
                </div>
                <p class="schedule__card--title mt-3 text-white">
                  Manage Employees
                </p>
                <p class="schedule__card--description text-white">
                  Manage all your employees and staff under the same platform.
                </p>
              </b-card>
            </b-col>

            <b-col cols="12" xl="3" lg="4" md="6" class="py-xl-0 py-3">
              <b-card class="schedule__cardBody">
                <div class="schedule__rectangle schedule__rectangle--card3">
                  <img src="../assets/Estimate.png" width="50%" />
                </div>
                <p class="schedule__card--title mt-3">Automated Billing</p>
                <p class="schedule__card--description">
                  Bill customers via mobile money and any other billing methods.
                </p>
              </b-card>
            </b-col>
            <b-col cols="12" xl="3" lg="4" md="6" class="py-xl-0 py-3">
              <b-card class="schedule__cardBody">
                <div class="schedule__rectangle schedule__rectangle--card4">
                  <img src="../assets/Web Advertising.png" width="50%" />
                </div>

                <p class="schedule__card--title mt-3">Run Ads & Campaigns</p>
                <p class="schedule__card--description">
                  Grow your customers & audience by running ads and campaigns in
                  Ahidi.
                </p>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Scheudle",
};
</script>

<style lang="scss" scoped>
.schedule {
  &__title {
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 70px;
    text-transform: capitalize;
  }
  &__alignSm {
    justify-content: center;
    @media (max-width: 767.98px) {
      text-align: -webkit-center;
    }
  }
  &__cardBody {
    // width: 258px;
    // height: 273.38px;
    border-radius: 8px;
    padding-bottom: 20px;
    border: 0;
  }
  &__rectangle {
    width: 56px;
    height: 56px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__card--title {
    font-style: normal;
    font-size: 19px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #2b2b2b;
  }
  &__card--description {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #646464;
  }
  &__cardBody--active {
    // width: 258px;
    // height: 300.38px;
    background: rgba(255, 130, 0, 0.87);
    border-radius: 8px;
    padding-bottom: 20px;
  }
  &__rectangle--card1 {
    background: #ff8200 !important;
  }
  &__rectangle--card2 {
    background: white !important;
  }
  &__rectangle--card3 {
    background: #ff7277 !important;
  }
  &__rectangle--card4 {
    background: #f8c42c !important;
  }
}
</style>
