<template>
  <header class="shadow-sm">
    <b-container>
      <div>
        <b-navbar
          toggleable="lg"
          type="light"
          variant="white"
          class="px-sm-2 px-0"
        >
          <b-navbar-brand href="#">
            <img src="../assets/android-chrome-192x192.png" width="40%" />
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item
                to="/"
                class="mx-lg-4 mx-3 my-1"
                exact
                active-class="active_nav"
                >Home</b-nav-item
              >
              <b-nav-item
                :to="{ path: '/', hash: '#for-vendors'}"
                class="mx-lg-4 mx-3 my-1"
                exact
                exact-active-class="active_nav"
                >For Vendors</b-nav-item
              >
              <b-nav-item
                :to="{ path: '/', hash: '#for-consumers'}"
                class="mx-lg-4 mx-3 my-1"
                exact
                active-class="active_nav"
                >For Consumers</b-nav-item
              >
              <b-nav-item
                to="/pricing"
                class="mx-lg-4 mx-3 my-1"
                exact
                active-class="active_nav"
                >Pricing Plan</b-nav-item
              >
              <b-nav-item :href="vendorUrl + '/login'" class="mx-3 my-1">Log in</b-nav-item>
              <b-button :href="vendorUrl + '/sign-up'" variant="warning" class="mx-3 text-white">
                Sign up
              </b-button>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </b-container>
  </header>
</template>

<script>
export default {
  name: "Navbar",
  computed: {
    vendorUrl() {
      return this.$store.getters.country.country_code === "KE" ? "https://vendor.ahidi.co.ke" : "https://vendor.ahidi.com.au";
    }
  },
};
</script>

<style>
.nav-link {
  color: black !important;
  padding: 5px 0px !important;
}
.nav-link:focus {
  /* text-decoration: underline !important;
  text-decoration-color: #ff8200 !important; */
}
.btn-warning {
  background: #ff8200 !important;
  border: #ff8200 !important;
}
.active_nav {
  border-bottom: 2px solid #ff8200;
}
</style>
