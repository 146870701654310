<template>
  <div>
    <div class="container">
      <b-carousel id="carousel-l" :interval="4000" no-hover-pause>
        <b-carousel-slide>
          <template #img>
            <b-row class="justify-content-center">
              <b-col xl="6" lg="8" cols="12" class="my-5">
                <img src="../assets/Website image.png" width="100%" />
                <!-- <div class="">
            <b-carousel
              id="carousel-l"
              style="text-shadow: 0px 0px 2px #000"
              img-width="100%"
            >
              <b-carousel-slide>
                <template #img>
                  <img
                    class="d-block img-fluid w-100"
                    width="100%"
                    src="../assets/Website image.png"
                    alt="image slot"
                  />
                </template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template #img>
                  <img
                    class="d-block img-fluid w-90"
                    width="90%"
                    src="../assets/Website image 2.png"
                    alt="image slot"
                  />
                </template>
              </b-carousel-slide>
            </b-carousel>
          </div> -->
              </b-col>
              <b-col xl="6" lg="8" cols="12" class="my-5"
                ><div>
                  <h1 class="build__title">
                    Build your own website & Get it Running in minutes!
                  </h1>
                  <p class="build__disc mt-5">
                    Need a website for your business? No problem, build one to
                    suit your shop and have it running in minutes.
                  </p>
                  <b-button
                    variant="warning"
                    class="text-white btn-lg mt-5 px-5 py-3"
                    :href="vendorUrl"
                  >
                    Get Started
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </template>
        </b-carousel-slide>
        <b-carousel-slide>
          <template #img>
            <b-row class="justify-content-center">
              <b-col xl="6" lg="8" cols="12" class="my-5">
                <img src="../assets/website 2.png" width="100%" />
                <!-- <div class="">
            <b-carousel
              id="carousel-l"
              style="text-shadow: 0px 0px 2px #000"
              img-width="100%"
            >
              <b-carousel-slide>
                <template #img>
                  <img
                    class="d-block img-fluid w-100"
                    width="100%"
                    src="../assets/Website image.png"
                    alt="image slot"
                  />
                </template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template #img>
                  <img
                    class="d-block img-fluid w-90"
                    width="90%"
                    src="../assets/Website image 2.png"
                    alt="image slot"
                  />
                </template>
              </b-carousel-slide>
            </b-carousel>
          </div> -->
              </b-col>
              <b-col xl="6" lg="8" cols="12" class="my-5"
                ><div>
                  <h1 class="build__title">
                    Tight schedule? Book an appointment any time.
                  </h1>
                  <p class="build__disc mt-5">
                    Customers can book appointments whenever they are available.
                    Get notifications minutes prior.
                  </p>
                  <b-button
                    variant="warning"
                    class="text-white btn-lg mt-5 px-5 py-3"
                    :href="vendorUrl"
                    >Learn more</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </template>
        </b-carousel-slide>
        <b-carousel-slide>
          <template #img>
            <b-row class="justify-content-center">
              <b-col xl="6" lg="8" cols="12" class="my-5">
                <img src="../assets/website 3.png" width="100%" />
                <!-- <div class="">
            <b-carousel
              id="carousel-l"
              style="text-shadow: 0px 0px 2px #000"
              img-width="100%"
            >
              <b-carousel-slide>
                <template #img>
                  <img
                    class="d-block img-fluid w-100"
                    width="100%"
                    src="../assets/Website image.png"
                    alt="image slot"
                  />
                </template>
              </b-carousel-slide>
              <b-carousel-slide>
                <template #img>
                  <img
                    class="d-block img-fluid w-90"
                    width="90%"
                    src="../assets/Website image 2.png"
                    alt="image slot"
                  />
                </template>
              </b-carousel-slide>
            </b-carousel>
          </div> -->
              </b-col>
              <b-col xl="6" lg="8" cols="12" class="my-5"
                ><div>
                  <h1 class="build__title">A wide range of payment options.</h1>
                  <p class="build__disc mt-5">
                    Fast and easy payments from cards to mobile money. Payment
                    is complete in minutes!
                  </p>
                  <b-button
                    variant="warning"
                    class="text-white btn-lg mt-5 px-5 py-3"
                    :href="vendorUrl"
                  >
                    Learn more
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <!-- Manage Your Business -->
    <b-col
      md="11"
      offset-xl="1"
      class="pr-0 build pb-5 mr-xl-0 mr-auto ml-auto"
    >
      <div class="container-fluid my-5 py-5">
        <b-row class="justify-content-center">
          <b-col xl="5" lg="8" cols="12" class="my-5 manage"
            ><div>
              <h1 class="manage__title">
                Manage your Business from anywhere you are on web or mobile!
              </h1>
              <p class="manage__disc">
                Keep tabs with everything going on from the web and mobile. Be
                on top of everything from the tip of your fingers.
              </p>
            </div></b-col
          >
          <b-col xl="7" lg="8" cols="12" class="pr-0 mt-4">
            <img
              src="../assets/iphone and dashboard.png"
              width="100%"
              class="manage__img"
            />
          </b-col>
        </b-row>
      </div>
    </b-col>
  </div>
</template>

<script>
export default {
  computed: {
    vendorUrl() {
      return this.$store.getters.country.country_code === "KE" ? "https://vendor.ahidi.co.ke" : "https://vendor.ahidi.com.au";
    }
  },
};
</script>

<style lang="scss" scoped>
.build {
  &__title {
    font-family: "Ubuntu", sans-serif;
    line-height: 70px;
    color: #2b2b2b;
    text-transform: capitalize;
  }
  &__disc {
    font-weight: 500;
    font-size: 25px;
    line-height: 45px;
    color: #808095;
    padding-right: 20px;
  }
  .manage {
    &__title {
      font-family: "Ubuntu";
      font-size: 45px;
      line-height: 70px;
      color: #2b2b2b;
      text-transform: capitalize;
    }
    &__disc {
      font-weight: 500;
      font-size: 25px;
      line-height: 45px;
      color: #808095;
      text-transform: capitalize;
      padding-right: 20px;
    }
    &__img {
      // margin-left: -68px;
      // width: 112%;
      @media (max-width: 767.98px) {
        margin-left: 0px;
        width: 100%;
      }
    }
  }
}
.carousel_bg {
  background-image: url("../assets/Group 84981.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right, bottom;
  padding-bottom: 6rem;
}
</style>
